<template>
  <div class="container p-0">
    <div class="d-flex justify-content-end align-items-center">
      <button
        class="btn btn-black pointer-cursor"
        @click="createSessionHandler"
        :disabled="sessionUrl || createSessionLoading"
      >
        <i class="fas fa-spin fa-circle-notch" v-if="createSessionLoading"></i>
        <span v-else> Create Session </span>
      </button>
    </div>
    <div
      ref="iframeWrapper"
      class="
        position-relative
        mt-4
        light-shadow
        rounded
        bg-white
        session-container
      "
    >
      <span @click="toggleFullscreen" v-if="sessionUrl">
        <icon
          type="expand-arrows-alt"
          class="fullscreen-element expand"
          v-show="!isFullscreen"
        />
        <icon
          type="compress-arrows-alt"
          class="fullscreen-element compress"
          v-show="isFullscreen"
        />
      </span>
      <iframe
        v-if="sessionUrl"
        width="100%"
        height="100%"
        :src="sessionUrl"
        frameborder="0"
        allow="camera; microphone"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
      >
        Your browser doesn't support iframes
      </iframe>
      <div
        class="d-flex justify-content-center align-items-center h-100 w-100 p-3"
      >
        <i
          class="fas fa-spin fa-circle-notch fa-2x"
          v-if="createSessionLoading"
        ></i>
        <span v-if="!createSessionLoading" class="h3 text-center">
          No Session Active, use the button above to create a session.
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "CheckIn",
  data() {
    return {
      createSessionLoading: false,
      sessionUrl: "",
      isFullscreen: false,
    };
  },
  created() {
    document.addEventListener("fullscreenchange", () => {
      document.fullscreenElement
        ? (this.isFullscreen = true)
        : (this.isFullscreen = false);
    });
  },
  destroyed() {
    document.removeEventListener("fullscreenchange", () => {
      document.fullscreenElement
        ? (this.isFullscreen = true)
        : (this.isFullscreen = false);
    });
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      patient: (state) => state.patients.patient,
    }),
  },
  methods: {
    ...mapActions({
      createSession: "okaya/createSession",
      getSessions: "okaya/getSessions",
    }),
    createSessionHandler: async function () {
      const return_url = `${window.location.origin}/okaya-return`;
      this.createSessionLoading = true;
      const data = await this.createSession({
        user_id: this.patient.user_id,
        return_url,
      });
      this.sessionUrl = data?.data?.redirect_url || "";
      this.createSessionLoading = false;
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
      const iframeWrapper = this.$refs.iframeWrapper;
      if (this.isFullscreen) {
        if (iframeWrapper.requestFullscreen) {
          iframeWrapper.requestFullscreen();
        } else if (iframeWrapper.mozRequestFullScreen) {
          iframeWrapper.mozRequestFullScreen(); // Firefox
        } else if (iframeWrapper.webkitRequestFullscreen) {
          iframeWrapper.webkitRequestFullscreen(); // Chrome, Safari, Opera
        } else if (iframeWrapper.msRequestFullscreen) {
          iframeWrapper.msRequestFullscreen(); // IE/Edge
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen(); // Firefox
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen(); // Chrome, Safari, Opera
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen(); // IE/Edge
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.session-container {
  height: 80vh;
  background: hsla(186, 33%, 94%, 1);
  background: linear-gradient(
    90deg,
    hsla(186, 33%, 94%, 1) 0%,
    hsla(216, 41%, 79%, 1) 100%
  );
  background: -moz-linear-gradient(
    90deg,
    hsla(186, 33%, 94%, 1) 0%,
    hsla(216, 41%, 79%, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    hsla(186, 33%, 94%, 1) 0%,
    hsla(216, 41%, 79%, 1) 100%
  );
  overflow: hidden;
  .fullscreen-element {
    right: 15px;
    top: 15px;
    font-size: 2rem;
    color: #000;
    transition: transform 0.3s;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  .expand {
    position: absolute !important;
  }
  .compress {
    position: fixed !important;
    z-index: 2147483647 !important;
  }
}
</style>
